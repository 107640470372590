<template>
  <graph
    class="current-past-timeline"
    width="100%"
    height="200px"
    :options="chartOptions"
    :series="series"
    v-if="account"
  />
</template>

<script>
export default {
  components: {
    graph: () => import('@/components/graph')
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          type: 'line',
          stacked: false
        },
        stroke: {
          width: [1, 3, 6]
        },
        labels: this.labels,
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          labels: {
            formatter: y => this.formatCurrency(y, this.account.currency)
          },
          min: 0
        },
        tooltip: {
          intersect: false,
          y: {
            formatter: y => this.formatCurrency(y, this.account.currency)
          }
        }
      }
    },
    labels () {
      let date = this.account?.date
      if (!date) { return null }

      date = this.moment(date)

      return this.account.days?.map(d => {
        const dd = this.moment(date)
        dd.add(d, 'days')
        return dd.valueOf()
      })
    },
    series () {
      return [
        {
          name: this.$t('t.Balance'),
          type: 'line',
          data: this.account.current.data.map((a, index) => a + this.account.due.data[index])
        },
        {
          name: this.$t('t.Net'),
          type: 'line',
          data: this.account.net.data
        },
        {
          name: this.$t('t.Risk'),
          type: 'line',
          data: this.account.risk.data
        }
      ]
    }
  },
  props: {
    account: Object
  }
}
</script>
